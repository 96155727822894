$mol_labeler_demo $mol_example_small
	title \Labeled content of some types
	sub /
		<= Provider $mol_labeler
			title \Provider
			content / \ACME Provider Inc.
		<= Name $mol_labeler
			title \User name
			Content <= Name_control $mol_string
				hint \Jack Sparrow
				value? <=> user_name? \
	tags /
		\label
		\form
		\field
		\caption
	aspects /
		\Widget/Layout
