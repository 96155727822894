namespace $.$$ {

	export class $mol_pick_demo extends $.$mol_pick_demo {
		
		delete_confirm() {
			this.Options_pop().showed( false )
		}

	}
}
