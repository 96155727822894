$mol_audio_demo $mol_example_small
	title \WebAudio API example
	Beep_room $mol_audio_room
		status? => beep_status?
		input /
			<= Beep_track $mol_audio_melody
				start => beep_play
				note_length .5
				notes \e
	Noise_room $mol_audio_room
		status? => noise_status?
		input /
			<= Noise $mol_audio_vibe
				active? => noise_active?
				stop_at? => noise_stop_at?
				freq_default <= noise_freq 0
	sub /
		<= List $mol_list rows /
			<= Beep_row $mol_row sub /
				<= Beep_play $mol_button_minor
					click? <=> beep_play_click? null
					title \Beep
				<= Beep_status $mol_audio_status status? <=> beep_status?
			<= Nouse_row $mol_row sub /
				<= Noise_play $mol_button_minor
					click? <=> noise_play_click? null
					title \Noise
				<= Noise_status $mol_audio_status status? <=> noise_status?
	tags /
		\sound
	aspects /
		\Media/Audio
