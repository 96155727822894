$mol_row_demo_form $mol_example
	title \Some controls in one row with equal paddings and wrapping support
	sub /
		<= Row $mol_row
			sub /
				<= Name $mol_search
					hint <= name_hint \Jack Sparrow
					query? <=> name? \
					suggests /
						<= suggest1 \Jack Sparrow
						<= suggest2 \Bruce Wayne
				<= Count $mol_number
					hint <= count_hint \Count
					value? <=> count? null
				<= Progress $mol_portion
					portion <= progress 0.33
				<= Publish $mol_check_box
					title <= publish_label \Shared
					checked? <=> publish? false
				<= Drop $mol_button_minor
					title <= drop_title \Drop
	tags /
		\row
		\container
		\flex
	aspects /
		\Widget/Layout
