$mol_svg_text $mol_svg
	dom_name \text
	pos /
	attr *
		^
		x <= pos_x \
		y <= pos_y \
		text-anchor <= align_hor <= align \middle
		alignment-baseline <= align_vert \baseline
	sub /
		<= text \
