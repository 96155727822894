$mol_app_report $mol_page
	title @ \Pump #1337 - Technical passport
	body /
		<= descriptor $mol_view
			sub / <= description \
		<= tabler $mol_app_report_tabler
			rows <= rows /
				<= headRower $mol_app_report_rower
					cells <= headCells /
	rower* $mol_app_report_rower
		cells <= rowerCells* /
	cell* $mol_app_report_cell
		content <= cell_content* null
		rows <= cellrows* 1
		cols <= cellCols* 1
	texter* $mol_view
		sub / <= cell_value*? null
	select* $mol_select
		value? <=> cell_value*? null
		dictionary <= cell_options* *
	number* $mol_number
		value? <=> cell_value*? null

$mol_app_report_tabler $mol_view
	dom_name \table
	sub <= rows /
	
$mol_app_report_rower $mol_view
	dom_name \tr
	sub <= cells /

$mol_app_report_cell $mol_view
	dom_name \td
	attr *
		^
		colspan <= cols 1
		rowspan <= rows 1
	sub / <= content null
