$mol_pick_time_demo $mol_example_small
	sub /
		<= Picker $mol_pick_time
			value_moment? <=> moment? $mol_time_moment / \T01:23
	tags /
		\$mol_time_moment
		\timepicker
		\time
		\datetime
	aspects /
		\Widget/Control/Button/Picker
		\Type/Time
