$mol_textarea_demo $mol_example
	title \Text input field in various states
	sub /
		<= Content $mol_list rows /
			<= Filled_descr $mol_textarea
				sidebar_showed true
				hint \source code
				value? <=> filled_descr? \
					\function hello( name = 'World' ) {
					\	return `Hello, ${ name }!`
					\}
			<= Disabled $mol_text
				text <= symbols_hint \
	tags /
		\code
		\syntax
		\highlight
	aspects /
		\Widget/Control
		\Type/String
