$mol_button_demo $mol_example_small
	title \All types of buttons in every states
	sub /
		<= Major_enabled $mol_button_major
			title \Enabled Major
			click?event <=> fail?event null
		-
		<= Major_disabled $mol_button_major
			title \Disabled Major
			enabled false
		-
		<= Minor_enabled $mol_button_minor
			title \Enabled Minor
			click?event <=> fail?event null
		-
		<= Minor_disabled $mol_button_minor
			title \Disabled Minor
			enabled false
		-
		<= Minor_icon_only $mol_button_minor
			click?event <=> fail?event null
			sub /
				<= Minor_icon_only_icon $mol_icon_cursor_default_click_outline
		-
		<= Minor_iconed $mol_button_minor
			click?event <=> fail?event null
			sub /
				<= Minor_iconed_icon $mol_icon_cursor_default_click_outline
				\Minor with Icon
	aspects /
		\Widget/Control/Button
