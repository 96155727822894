$mol_video_camera $mol_video_player
	controls false
	style *
		transform <= transform \
	video_constraints *
		facingMode <= facing \user
		aspectRatio <= aspect 1
		width * ideal <= width <= size 720
		height * ideal <= height <= size
	video_settings *
		brightness <= brightness 128
		sharpness <= sharpness 2
		contrast <= contrast 32
		saturation <= saturation 64
		advanced /
			* colorTemperature <= temperature 4000
			* torch <= torch false

