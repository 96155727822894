$hyoo_page $mol_book2
	side_main_id \iy8wtn_tky6pc
	yard $hyoo_sync_client
	side* $hyoo_page_side
		title => side_title*
		pages_node => pages_node*
	side_current $hyoo_page_side
	profile $hyoo_page_side
		bookmarks_node => bookmarks_node
	style *
		^
		backgroundImage <= aura_image \
	plugins /
		<= Theme $mol_theme_auto
	auto /
		<= ref_track null
	pages /
		<= News $hyoo_page_side_news
			side <= profile
		<= Side_menu $hyoo_page_side_menu
			yard <= yard
			side <= book_side $hyoo_page_side
			side_current <= side_current
			list <= book_pages_node null
			item_list* <= pages_node*
			item_uri* <= side_uri* \
			item_add*? <=> side_add*? null
			tools_ext <= tools_ext /
		<= View*0_0 $hyoo_page_side_view
			side <= side*
			peer* <= side*
			profile <= profile
			menu_showed? <=> side_menu_showed? false
			editing? <=> editing? false
			info? <=> info? false
			highlight <= search
			Details => View_details*
		<= Edit*0_0 $hyoo_page_side_edit
			side <= side*
			rights? <=> rights? false
			close? <=> edit_close*? null
		<= Info*0_0 $hyoo_page_side_info
			side <= side*
			close? <=> info_close*? null
			Text <= View_details*
		<= Rights*0_0 $hyoo_meta_rights
			meta <= side*
			peer* <= side*
			tools /
				<= Rights_close $mol_button_minor
					click? <=> rights_close? null
					sub /
						<= Close_icon $mol_icon_close
		<= Safe $hyoo_meta_safe
			yard <= yard
			tools /
				<= Safe_close $mol_button_minor
					click? <=> safe_close? null
					sub /
						<= Safe_icon $mol_icon_close
	Placeholder <= Menu $hyoo_page_menu
		yard <= yard
		side <= profile
		list <= bookmarks_node
		item_uri* <= side_uri* \
		add? <=> page_add? null
		item_add*? <=> side_add*? null
		aura_showing? <=> aura_showing? true
		safe_showing? <=> safe? false
		search => search
