$mol_search_demo $mol_example_small
	title \Search field with suggest 
	sub /
		<= Search $mol_search
			query => query
			suggests <= suggests /
	tags /
		\search
		\suggest
		\autocomplete
		\string
		\fulltext
		\filter
	aspects /
		\Widget/Control
		\Type/String
