namespace $ {
	
	$mol_style_define( $hyoo_page, {
		
		background: {
			size: [ 'cover' ],
			position: 'center',
		},
		
	} )
	
}
