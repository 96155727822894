namespace $.$$ {
	
	$mol_style_define( $mol_transit_demo, {
		
		Justify: {
			flex: {
				grow: 0,
			},
		},
		
	} )
	
}
