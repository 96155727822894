$mol_svg_text_box $mol_svg_group
	font_size 16
	width 0
	sub /
		<= Back $mol_svg_rect
			width <= box_width \0.5rem
			height <= box_height \1rem
			pos /
				<= box_pos_x <= pos_x
				<= box_pos_y \0
		<= Text $mol_svg_text
			pos /
				<= pos_x \0
				<= pos_y \100%
			align <= align \start
			sub /
				<= text \
