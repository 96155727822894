$mol_chart_legend $mol_scroll
	graphs /$mol_plot_graph
	graphs_front /$mol_plot_graph
	sub /
		<= Gallery $mol_gallery items <= graph_legends /$mol_view
	-
	Graph_legend* $mol_view
		sub /
			<= Graph_sample_box* $mol_view
				sub /
					<= Graph_sample* null
			<= Graph_title* $mol_view
				sub /
					<= graph_title* \
