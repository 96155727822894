namespace $ {
	
	$mol_style_define( $hyoo_meta_link, {
		
		Title: {
			flex: {
				shrink: 1,
			},
		},
		
	} )
	
}
