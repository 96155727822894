$mol_data_nullable_demo $mol_example_code
	code? \
		\const Age = $mol_data_nullable( $mol_data_integer )
		\const age1 = Age( 18 ) // ✅
		\const age2 = Age( null ) // ✅
		\
		\Age( 'xxx' ) // ❌ xxx is not a number
	tags /
		\runtime
		\validation
		\nullable
	aspects /
		\Algorithm/Assert
