$mol_bench_demo $mol_example_small
	title \Benchmarking results visualization
	sub /
		<= View $mol_bench
			col_sort? <=> col_sort? \mid
			result <= result *
	tags /
		\perfomance
		\comparison
	aspects /
		\Widget/Grid
