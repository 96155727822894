$mol_fetch_demo $mol_example_small
	title \Simple spoiler
	sub /
		<= Content $mol_list rows /
			<= Request $mol_view sub /
				<= Url $mol_string
					value? <=> url? \https://jsonplaceholder.typicode.com/users
				<= Fetch $mol_button_major
					click? <= fetch_data? null
			<= Data $mol_dump_value
				value <= data? null
	tags /
		\fetch
		\load
		\api
		\response
		\request
	aspects /
		\Network/HTTP
