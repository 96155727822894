$mol_section_demo_level $mol_example_small
	title \Section with header
	sub /
		<= Section1
	Section1 $mol_section
		level 1
		title \Level 1
		content /
			<= Section1_text $mol_filler
				min_symbols 250
			<= Section2
	Section2 $mol_section
		level 2
		title \Level 2
		content /
			<= Section2_text $mol_filler
				min_symbols 250
			<= Section3
	Section3 $mol_section
		level 3
		title \Level 3
		content /
			<= Section3_text $mol_filler
				min_symbols 250
			<= Section4
	Section4 $mol_section
		level 4
		title \Level 4
		content /
			<= Section4_text $mol_filler
				min_symbols 250
			<= Section5
	Section5 $mol_section
		level 5
		title \Level 5
		content /
			<= Section5_text $mol_filler
				min_symbols 250
			<= Section6
	Section6 $mol_section
		level 6
		title \Level 6
		content /
			<= Section6_text $mol_filler
				min_symbols 250
			<= Section7
	Section7 $mol_section
		level 7
		title \Level 7
		content /
			<= Section7_text $mol_filler
				min_symbols 250
	tags /
		\header
		\level
		\h1
	aspects /
		\Widget/Layout
