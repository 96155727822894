$mol_date $mol_pick
	Icon $mol_icon_calendar
	-
	bubble_content /
		<= Input_row $mol_view sub <= input_content /
			<= Today $mol_button_minor
				hint @ \Today
				enabled <= enabled true
				click?event <=> today_click?event null
				sub /
					<= Today_icon $mol_icon_calendar_today
			<= Input $mol_format
				value? <=> value? \
				value_changed? => value_changed?
				mask* <= input_mask* \
				enabled <= enabled true
			<= Clear $mol_button_minor
				hint @ \Clear
				enabled <= enabled true
				click?event <=> clear?event null
				sub /
					<= Clear_icon $mol_icon_trash_can_outline
		<= Calendar $mol_date_calendar
			enabled <= enabled true
			month_moment <= month_moment <= value_moment
			day_selected* <= day_selected* false
			day_click*?event <=> day_click*?event null
			Title => Calendar_title
			head /
				<= Calendar_tools $mol_view
					sub /
						<= Prev $mol_button_minor
							hint <= prev_hint @ \Previous month
							click?event <=> prev?event null
							sub /
								<= Prev_icon $mol_icon_chevron_left
						<= Calendar_title
						<= Next $mol_button_minor
							hint <= next_hint @ \Next month
							click?event <=> next?event null
							sub /
								<= Next_icon $mol_icon_chevron_right
	-
	value_number? +NaN
	value_moment? $mol_time_moment

$mol_date_calendar $mol_calendar
	day_content* /
		<= Day_button* $mol_button_minor
			title <= day_text*
			event_click?event <=> day_click*?event null
			minimal_height 24
			enabled <= enabled true
