$mol_calendar_demo_simple $mol_example_small
	title \Days of curret month
	sub /
		<= Calendar $mol_calendar
			month_moment <= today $mol_time_moment
	tags /
		\month
	aspects /
		\Widget/Grid
		\Type/Date
