$mol_paginator $mol_bar
	sub /
		<= Backward $mol_button_minor
			hint <= backward_hint @ \Backward
			click?event <=> backward?event null
			sub / <= Backward_icon $mol_icon_chevron_left
		<= Value $mol_view sub / <= value? 0
		<= Forward $mol_button_minor
			hint <= forward_hint @ \Forward
			click?event <=> forward?event null
			sub / <= Forward_icon $mol_icon_chevron_right
