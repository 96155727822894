namespace $ {
	
	$mol_style_define( $mol_hor , {
		display: 'flex',
		alignItems: 'flex-start',
		alignContent: 'flex-start',
		justifyContent: 'flex-start',
		flex: {
			grow: 1,
			shrink: 0,
			basis: 'auto',
		},
	} )

}
