$mol_select_demo_priority $mol_example_small
	title \Priority picker
	sub /
		<= Priority $mol_select
			Filter null
			value? <=> priority? \Lowest
			options /
				\Highest 
				\High
				\Medium
				\Low
				\Lowest
	tags /
		\select
		\priority
	aspects /
		\Widget/Control
