namespace $ {
	
	export class $mol_spell_any extends Object {
		
		static test( word: string ) {
			return $mol_spell_ru.test( word )
		}
		
	}
	
}
