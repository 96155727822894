namespace $ {
	
	$mol_style_define( $mol_nav_demo , {
		display : 'flex',
		flexDirection : 'column',
		Row_list : {
			display : 'flex',
			flexDirection : 'column',
		},
	})

}
