$mol_audio_status $mol_view
	icons *
		closed <= Closed $mol_icon_power_sleep
		suspended <= Suspended $mol_icon_sleep
		playing <= Playing $mol_icon_play
		running <= Running $mol_icon_pause
	status? \suspended
	status_template \Audio status: {status}
	sub /
		<= Wakeup $mol_button_minor
			click? <=> wakeup? null
			enabled <= wakeup_enabled false
			hint <= status_name <= status?
			sub / <= Icon $mol_icon_play
