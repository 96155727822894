$mol_plot_bar $mol_plot_graph
	style *
		^
		stroke-width <= stroke_width \1rem
	sub /
		<= Hint
		<= Curve $mol_svg_path
			geometry <= curve \
	Sample $mol_plot_graph_sample
		color <= color
