$mol_plot_mark_cross $mol_plot_graph
	labels /string
	title_x_gap 4
	title_y_gap 22
	threshold 16
	graphs /$mol_plot_graph
	dimensions $mol_vector_2d<$mol_vector_range<number>> /
		<= dimensions_x $mol_vector_range<number> /
			Infinity
			-Infinity
		<= dimensions_y $mol_vector_range<number> /
			Infinity
			-Infinity
	sub /
		<= Curve $mol_svg_path
			geometry <= curve \
		<= Label_x $mol_svg_text_box
			pos_x <= title_x_pos_x \0
			pos_y <= title_x_pos_y \100%
			text <= title_x \
		<= Label_y $mol_svg_text_box
			pos_x <= title_y_pos_x \0
			pos_y <= title_y_pos_y \0
			text <= title_y \
