$mol_drag $mol_ghost
	event *
		dragstart?event <=> drag_start?event <=> start?event null
		drag?event <=> drag_move?event <=> move?event null
		dragend?event <=> drag_end?event <=> end?event null
	attr *
		draggable true
		mol_drag_status <= status? \ready
	transfer *
		text/plain \
		text/html \
		text/uri-list \
	allow_copy true
	allow_link true
	allow_move true
	image <= dom_node
