$mol_bench $mol_grid
	records <= result *
	col_sort? \
	-
	Col_head* $mol_bench_head
		event_click? <=> event_sort_toggle*? null
		sub <= col_head_content* /
			<= col_head_title* \
			<= Col_head_sort* $mol_icon_sort_asc
	-
	cell_content_number* /
		<= result_value* \
		<= Result_portion* $mol_portion
			portion <= result_portion* 0


$mol_bench_head $mol_float
	horizontal false
	event *
		^
		click? <=> event_click? null
	attr *
		^
		title <= hint @ \Click to sort by this column
