namespace $ {
	export let $hyoo_lingua_langs = {
		af: 'Afrikaans',
		am: 'Amharic',
		ar: 'Arabic',
		ast: 'Asturian',
		az: 'Azerbaijani',
		ba: 'Bashkir',
		be: 'Belarusian',
		bg: 'Bulgarian',
		bn: 'Bengali',
		br: 'Breton',
		bs: 'Bosnian',
		ca: 'Catalan',
		ceb: 'Cebuano',
		cs: 'Czech',
		cy: 'Welsh',
		da: 'Danish',
		de: 'German',
		el: 'Greeek',
		en: 'English',
		es: 'Spanish',
		et: 'Estonian',
		fa: 'Persian',
		ff: 'Fulah',
		fi: 'Finnish',
		fr: 'French',
		fy: 'West Frisian',
		ga: 'Irish',
		gd: 'Gaelic',
		gl: 'Galician',
		gu: 'Gujarati',
		ha: 'Hausa',
		he: 'Hebrew',
		hi: 'Hindi',
		hr: 'Croatian',
		ht: 'Haitian',
		hu: 'Hungarian',
		hy: 'Armenian',
		id: 'Indonesian',
		ig: 'Igbo',
		ilo: 'Iloko',
		is: 'Icelandic',
		it: 'Italian',
		ja: 'Japanese',
		jv: 'Javanese',
		ka: 'Georgian',
		kk: 'Kazakh',
		km: 'Central Khmer',
		kn: 'Kannada',
		ko: 'Korean',
		lb: 'Luxembourgish',
		lg: 'Ganda',
		ln: 'Lingala',
		lo: 'Lao',
		lt: 'Lithuanian',
		lv: 'Latvian',
		mg: 'Malagasy',
		mk: 'Macedonian',
		ml: 'Malayalam',
		mn: 'Mongolian',
		mr: 'Marathi',
		ms: 'Malay',
		my: 'Burmese',
		ne: 'Nepali',
		nl: 'Dutch',
		no: 'Norwegian',
		ns: 'Northern Sotho',
		oc: 'Occitan',
		or: 'Oriya',
		pa: 'Panjabi',
		pl: 'Polish',
		ps: 'Pushto',
		pt: 'Portuguese',
		ro: 'Romanian',
		ru: 'Russian',
		sd: 'Sindhi',
		si: 'Sinhala',
		sk: 'Slovak',
		sl: 'Slovenian',
		so: 'Somali',
		sq: 'Albanian',
		sr: 'Serbian',
		ss: 'Swati',
		su: 'Sundanese',
		sv: 'Swedish',
		sw: 'Swahili',
		ta: 'Tamil',
		th: 'Thai',
		tl: 'Tagalog',
		tn: 'Tswana',
		tr: 'Turkish',
		uk: 'Ukrainian',
		ur: 'Urdu',
		uz: 'Uzbek',
		vi: 'Vietnamese',
		wo: 'Wolof',
		xh: 'Xhosa',
		yi: 'Yiddish',
		yo: 'Yoruba',
		zh: 'Chinese',
		zu: 'Zulu',
	}
}
