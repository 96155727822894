namespace $.$$ {
	
	export class $mol_svg_text extends $.$mol_svg_text {
		
		pos_x() {
			return this.pos()[ 0 ]
		}
		
		pos_y() {
			return this.pos()[ 1 ]
		}
		
	}
	
}
