namespace $ {
	
	const { rem } = $mol_style_unit
	
	$mol_style_define( $hyoo_page_side_news, {
		
		flex: {
			basis: rem(20),
			grow: 0,
		},
		
	} )
	
}
