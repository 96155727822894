$mol_password $mol_view
	type? \password
	sub <= content /
		<= Pass $mol_string
			type <= type
			hint <= hint \
			value? <=> value? \
			submit?event <=> submit?event null
			enabled <= enabled true
		<= Show $mol_check_icon
			checked? <=> checked? true
			Icon <= Show_icon $mol_icon_eye
