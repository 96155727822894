namespace $.$$ {

	const { rem } = $mol_style_unit

	$mol_style_define( $mol_avatar_demo , {
		Avatar: {
			width: rem( 2 ),
			height: rem( 2 )
		}
	} )

}
