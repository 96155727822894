namespace $.$$ {
	
	$mol_style_define( $mol_transit, {
		
		transition: 'none',
		
		animation: {
			duration: `.25s`,
			timingFunction: `ease-out`,
		},
		
	} )
	
}
