namespace $.$$ {

	const { rem } = $mol_style_unit

	$mol_style_define( $mol_select_list , {
		
		flex: {
			wrap: 'wrap',
			shrink: 1,
			grow: 1,
		},
		
	} )
	
}
