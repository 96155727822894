$mol_select_list $mol_view
	value? /string
	dictionary *
	badges_list <= Badges /$mol_view
	Badge* $mol_button_minor
		title <= badge_title* \badge
		click?event <=> remove*?event null
		hint <= badge_hint @ \Drop
		enabled <= drop_enabled <= enabled true
	sub /$mol_view
		<= Pick $mol_select
			event_select*? <=> event_select*? null
			align_hor <= align_hor \right
			options <= options_pickable <= options /string
			value? <=> pick? \
			option_label* <= option_title* \
			trigger_enabled <= pick_enabled <= enabled true
			hint <= pick_hint @ \Add..
			filter_pattern? => filter_pattern?
			Trigger_icon <= Pick_icon $mol_icon_plus
		^ badges_list
