$mol_code_demo $mol_example_small
	title \Barcode scanner with various formats support
	sub /
		<= Qr $mol_code
			format \QR_CODE
		<= Matrix $mol_code
			format \DATA_MATRIX
		<= Upc_e $mol_code
			format \UPC_E
		<= Upc_a $mol_code
			format \UPC_A
		<= Ean_8 $mol_code
			format \EAN_8
		<= Ean_13 $mol_code
			format \EAN_13
		<= Code_128 $mol_code
			format \CODE_128
		<= Code_39 $mol_code
			format \CODE_39
		<= Itf $mol_code
			format \ITF
	tags /
		\qrcode
		\barcode
		\scan
		\cordova
	aspects /
		\Widget/Control
