namespace $ {

	/** Composed layout. */
	export class $mol_layout_tree extends $mol_layout {
		
		kids = [] as $mol_layout[]
		ortho = null as null | $mol_layout_tree
		
	}
	
}
