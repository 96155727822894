namespace $ {

	/** Line breaks policy. */
	export const enum $mol_layout_break {
		taboo = 'taboo',
		allow = 'allow',
		force = 'force',
	}
	
}
