$mol_plot_ruler $mol_plot_graph
	step 0
	scale_axis 1
	scale_step 1
	shift_axis 1
	dimensions_axis $mol_vector_range<number> /
		Infinity
		-Infinity
	viewport_axis $mol_vector_range<number> /
		Infinity
		-Infinity
	axis_points /number
	normalize? 0
	precision 1
	sub /
		<= Background $mol_svg_rect
			pos_x <= background_x \0
			pos_y <= background_y \0
			width <= background_width \100%
			height <= background_height \14
		<= Curve $mol_svg_path
			geometry <= curve \
		<= labels_formatted /
		<= Title $mol_svg_text
			pos_x <= title_pos_x \0
			pos_y <= title_pos_y \100%
			align <= title_align \start
			text <= title
	Label* $mol_svg_text
		pos <= label_pos* /
			<= label_pos_x* \
			<= label_pos_y* \
		text <= label_text* \
		align <= label_align \
