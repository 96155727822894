$mol_map_yandex_demo $mol_example_large
	title \Simple Yandex Maps wrapper
	sub /
		<= Map $mol_map_yandex
			objects /
				<= Place $mol_map_yandex_mark
					title <= place_title \
					address <= place_addres \Saint-Petersburg
					content <= place_content \It is Russia's second-largest city after Moscow
	aspects /
		\Integration
		\Widget/Map
