$mol_form $mol_list
	submit_allowed true
	submit_blocked false
	event *
		^
		keydown?event <=> keydown?event null
	submit?event null
	rows /
		<= Body $mol_list
			sub <= body <= form_fields /$mol_form_field
		<= Foot $mol_row
			sub <= foot <= buttons /$mol_view
