$jin_keyboard $mol_view
	area $mol_view
	turn? null
	input? null
	Row* $jin_keyboard_row
		layout <= row_layout* /
		turn? <=> row_turn*? null
		input? <=> row_input*? null
		upcase <= upcase? false
	sub /
		<= Row*0
		<= Row*1
		<= Row*2
		<= Row*3
	layout? \
	layouts *
		\
			/
				/
					\Б
					\И
					\П
				/
					\"
					\ 
					\.
				/
					\F
					\I
					\N
				/
					\@
					\0
					\+
		\ 
			/
				/
					\⏮
					\🔠
					\⏭
				/
					\◀
					\ 
					\▶
				/
					\🤛
					\🔻
					\🤜
					\
				/
					\↩
					\
					\↪
		\0
			/
				/
					\1
					\2
					\3
				/
					\4
					\5
					\6
				/
					\7
					\8
					\9
				/
					\
					\0
					\#
		\П
			/
				/
					\К
					\Т
					\П
				/
					\Ф
					\Х
					\С
				/
					\Ч
					\Ш
					\Щ
				/
					\
					\Ъ
					\Ц
		\И
			/
				/
					\А
					\И
					\У
				/
					\Я
					\Й
					\Ю
				/
					\Э
					\Ы
					\О
				/
					\Е
					\
					\Ё
		\Б
			/
				/
					\Б
					\В
					\Г
				/
					\Д
					\Ж
					\З
				/
					\Л
					\М
					\Н
				/
					\Р
					\Ь
					\
		\.
			/
				/
					\%
					\!
					\?
				/
					\—
					\,
					\.
				/
					\•
					\;
					\:
				/
					\
					\'
					\…
		\"
			/
				/
					\(
					\{
					\[
				/
					\"
					\«
					\»
				/
					\)
					\}
					\]
				/
					\<
					\>
					\
		\+
			/
				/
					\
					\^
					\≠
				/
					\\
					\_
					\=
				/
					\|
					\×
					\*
				/
					\/
					\-
					\+
		\@
			/
				/
					\€
					\¥
					\
				/
					\$
					\₽
					\§
				/
					\°
					\`
					\✔
				/
					\@
					\&
					\~
		\F
			/
				/
					\
					\
					\
				/
					\H
					\K
					\T
				/
					\F
					\P
					\S
				/
					\W
					\X
					\
		\I
			/
				/
					\
					\
					\
				/
					\Y
					\O
					\U
				/
					\A
					\I
					\E
				/
					\
					\J
					\
		\N
			/
				/
					\
					\B
					\C
				/
					\D
					\G
					\R
				/
					\L
					\M
					\N
				/
					\Q
					\V
					\Z

$jin_keyboard_row $mol_view
	turn? null
	input? null
	layout /
		\F
		\I
		\N
	Cell* $jin_keyboard_cell
		symbol <= cell_title* \?
		turn? <=> cell_turn*? null
		input? <=> cell_input*? null
		upcase <= upcase true
	sub /
		<= Cell*0
		<= Cell*1
		<= Cell*2

$jin_keyboard_cell $mol_view
	turn? null
	input? null
	upcase true
	event *
		^
		pointerdown? <=> start? null
		pointerup? <=> end? null
		pointercancel? <=> abort? null
	sub /
		<= title <= symbol \
