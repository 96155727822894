$mol_data_tagged_demo $mol_example_code
	code? \
		\const { Weight, Length } = $mol_data_tagged({
		\	Weight: $mol_data_integer,
		\	Length: $mol_data_integer,
		\})
		\
		\let weight = Weight( 50 ) // ✅
		\weight = Length( 50 ) // ❌ Type '"Weight"' is not assignable to type '"Length"'
	tags /
		\runtime
		\validation
		\tagged
	aspects /
		\Algorithm/Assert
