$mol_html_view_demo $mol_example
	title \View raw HTML
	sub /
		<= Html $mol_html_view
			html \
				\ <h1>HTML Example</h1>
				\ <h2>Headings</h2>
				\ 	<h3>Level 3</h3>
				\ 	<h4>Level 4</h4>
				\ 	<h5>Level 5</h5>
				\ 	<h6>Level 6</h6>
				\ <h2>Inline elements</h2>
				\ <p>
				\ 	<strong>strong</strong>,
				\ 	<em>emphasis</em>,
				\ 	<ins>inserted</ins>,
				\ 	<del>deleted</del>,
				\ 	<br />
				\ 	<b>bold</b>,
				\ 	<i>italic</i>,
				\ 	<u>underlined</u>,
				\ 	<s>strikethrough</s>,
				\ 	<br />
				\ 	<code>code</code>,
				\ 	<a href="#">safe link</a>,
				\ 	<a href="javascript:alert(1)">unsafe link</a>,
				\ 	normal text.
				\ </p>
				\ <h2>Media elements</h2>
				\ <p>
				\ 	<img src="https://mol.hyoo.ru/mol/logo/logo.svg" />
				\ </p>
				\ <h2>Block elements</h2>
				\ <blockquote><p>Block quotation</p></blockquote>
				\ <pre><code>Block code</code></pre>
	tags /
		\html
		\render
	aspects /
		\Widget
		\Type/HTML
