$mol_drag_demo $mol_example_large
	task_count 100
	sub /
		<= List_drop $mol_drop
			adopt?transfer <=> transfer_adopt?transfer null
			receive?obj <=> receive?obj null
			Sub <= Page $mol_page
				head / <= Trash_drop $mol_drop
					adopt?transfer <=> transfer_adopt?transfer null
					receive?obj <=> receive_trash?obj null
					Sub <= Trash $mol_view sub /
						<= Trash_icon $mol_icon_trash_can_outline
						\ Trash
				Body_content <= List $mol_list
					rows <= task_rows /
	Task_row* $mol_drag
		transfer *
			text/plain <= task_title* \
			text/html <= task_html* \
			text/uri-list <= task_uri* \
		Sub <= Task_drop* $mol_drop
			adopt?transfer <=> transfer_adopt?transfer null
			receive?obj <=> receive_before*?obj null
			Sub <= Task_link* $mol_link
				uri <= task_uri* \
				sub / <= task_title* \
	tags /
		\drag
		\dragndrop
		\reorder
		\transfer
	aspects /
		\Widget/Plugin
		\Drag'n'Drop
