$mol_data_pipe_demo $mol_example_code
	code? \
		\const Birthday = $mol_data_pipe(
		\	$mol_data_string,
		\	$mol_time_moment,
		\	( moment: $mol_time_moment )=> moment.toOffset( 'Z' ),
		\)
		\const birthday = Birthday( '2023-01-06' ) // ✅
		\
		\Birthday( 123 ) // ❌ 2023-01-06 is not a number
	tags /
		\runtime
		\validation
		\pipe
	aspects /
		\Algorithm/Assert
		\Algorithm/Compose
