$mol_chart $mol_view
	gap_hor 48
	gap_vert 24
	gap_left <= gap_hor
	gap_right <= gap_hor
	gap_bottom <= gap_vert
	gap_top <= gap_vert
	graphs /$mol_plot_graph
	sub /
		<= Legend $mol_chart_legend
			graphs <= graphs_colored
		<= Plot $mol_plot_pane
			scale_x? => zoom?
			zoom? <=> zoom?
			gap_left <= gap_left
			gap_right <= gap_right
			gap_bottom <= gap_bottom
			gap_top <= gap_top
			graphs <= graphs
			graphs_colored => graphs_colored
			hue_base <= hue_base 210
			hue_shift <= hue_shift 163
