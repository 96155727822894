$mol_avatar_demo $mol_example_small
	title \Avatar uniquely-generated by id
	sub /
		<= Avatar_id_label $mol_labeler
			title \Id
			content /
				<= Avatar_id_value $mol_string
					value? <=> avatar_id? \$mol_avatar
		<= Avatar_label $mol_labeler
			title \Avatar
			content /
				<= Avatar $mol_avatar
					id <= avatar_id -
	tags /
		\generated
		\identity
		\user
	aspects /
		\Widget/Draw
		\Algorithm/Hash
