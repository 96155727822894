namespace $.$$ {
	
	$mol_style_define( $hyoo_meta_safe, {
		
		flex: {
			basis: `20rem`,
		},
		
		Export_block: {
			gap: $mol_gap.block,
		},
		
		Import_block: {
			gap: $mol_gap.block,
		},
		
	} )
	
}
