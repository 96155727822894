$mol_plot_line $mol_plot_graph
	threshold 1
	spacing 2
	color_fill \none
	dom_name \path
	attr *
		^
		d <= curve \
	sub /
		<= Hint
	-
	Sample $mol_plot_graph_sample
		color <= color
		type <= type
