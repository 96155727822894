namespace $.$$ {
	
	export class $mol_theme_demo extends $.$mol_theme_demo {
		
		hue_deg() {
			return this.hue() + 'deg'
		}
		
		hue_spread_deg() {
			return this.hue_spread() + 'deg'
		}
		
	}
	
}
