namespace $.$$ {

	export class $mol_calendar_demo_simple extends $.$mol_calendar_demo_simple {

		month_name() {
			return this.today().toString( 'Month YYYY' )
		}

	}

}
