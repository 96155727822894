$mol_paginator_demo $mol_example_small
	title \Page switcher
	sub /
		<= Pages $mol_paginator
			value? <=> page? 0
	tags /
		\paginator
		\navigation
	aspects /
		\Widget/Control
		\Type/Number/Integer
