$mol_data_optional_demo $mol_example_code
	code? \
		\const Age = $mol_data_optional( $mol_data_integer )
		\const age1 = Age( 18 ) // ✅
		\const age2 = Age( undefined ) // ✅
		\
		\Age( 'xxx' ) // ❌ xxx is not a number
	tags /
		\runtime
		\validation
		\optional
		\maybe
	aspects /
		\Algorithm/Assert
