$mol_calendar $mol_list
	sub /
		<= Head $mol_view
			sub <= head /
				<= Title $mol_view
					minimal_height 24
					sub /
						<= title \
		<= Weekdays $mol_hor
			sub <= weekdays /$mol_view
	weeks /$mol_view
	weeks_count 6
	-
	Weekday* $mol_calendar_day
		holiday <= weekend* false
		sub /
			<= weekday* \
	-
	Week* $mol_hor
		sub <= week_days* /$mol_view
	-
	Day* $mol_calendar_day
		ghost <= day_ghost* false
		holiday <= day_holiday* false
		selected <= day_selected* false
		today <= day_today* false
		theme <= day_theme* null
		sub <= day_content* /
			<= day_text* \
	-
	month_string \
	month_moment $mol_time_moment

$mol_calendar_day $mol_view
	minimal_height 24
	minimal_width 36
	attr *
		mol_calendar_holiday <= holiday false
		mol_calendar_ghost <= ghost false
		mol_calendar_selected <= selected false
		mol_calendar_today <= today false
		mol_theme <= theme null
