$hyoo_crowd_text_demo $mol_example_large
	title \CROWD Text Merge
	sub /
		<= Sandbox $hyoo_crowd_app
	tags /
		\text
		\merge
	aspects /
		\Application
		\Type/String
		\Algorithm/Merge
