$mol_data_integer_demo $mol_example_code
	code? \
		\const Age = $mol_data_integer
		\const age = Age( 18 ) // ✅
		\
		\Age( 18.5 ) // ❌ 18.5 is not an integer
	tags /
		\runtime
		\validation
	aspects /
		\Algorithm/Assert
		\Type/Number/Integer
