$mol_data_string_demo $mol_example_code
	code? \
		\const Name = $mol_data_string
		\const name = Name( 'Jin' ) // ✅
		\
		\Name( 7 ) // ❌ 7 is not a string
	tags /
		\runtime
		\validation
	aspects /
		\Algorithm/Assert
		\Type/String
