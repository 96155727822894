$mol_attach_demo $mol_example_small
	title \Attach files an show them
	sub /
		<= Filled $mol_attach
			items? <=> filled_items? /
				\https://picsum.photos/200
	tags /
		\file
		\image
		\upload
	aspects /
		\Widget/Control
		\Type/File
