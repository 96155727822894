$mol_date_demo $mol_example_small
	sub /
		<= Current $mol_date
			value_moment? <=> date_current? $mol_time_moment
		<= Formatted $mol_view
			sub /
				<= formatted \
		<= Empty $mol_date
			value_moment? <=> date_empty? null
	tags /
		\time
		\datepicker
		\format
	aspects /
		\Widget/Control/Button/Picker
		\Type/Date
