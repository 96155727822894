$hyoo_meta_safe $mol_page
	title @ \Private keys
	yard $hyoo_sync_yard<any>
	bid_pass_long @ \> 7 letters
	key_size 144
	attr *
		^
		mol_theme \$mol_theme_special
	body /
		<= Content $mol_list rows <= content /
			<= Export_block $mol_list rows <= export_rows /
				<= Expot_bid $mol_text text @ \Your private access keys are stored in the browser. In order not to lose them or transfer to another device, it is recommended to come up with a strong password for exporting them.
				<= Export_pass $mol_list rows /
					<= Password_field $mol_form_field
						name @ \Password
						bids / <= password_bid \
						Content <= Password $mol_password
							value? <=> password? \
					<= Recall_field $mol_form_field
						name @ \Hint for recall
						Content <= Recall $mol_string
							enabled <= recall_enabled true
							value? <=> recall? \
				<= Export_link $mol_link
					uri <= export_link \
					title @ \This link contains your encrypted prvate keys. Save it to a secure place on another physical device. Using this link you can restore your private keys by enterig same password.
			<= Import_block $mol_list rows <= import_rows /
				<= Iport_descr $mol_text text @ \
					\To import a private key, you must enter the password from it.
					\**If the current key is not saved, it will be lost forever.**
				<= Import_pass $mol_list rows /
					<= Recall_field
					<= Password_field
				<= Import_switch $mol_button_minor
					click? <=> import_switch? null
					sub /
						<= Peer_new $mol_avatar id <= peer_new \
						<= impot_switch_title @ \Switch
