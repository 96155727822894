$mol_audio_demo_sample $mol_example_small
	title \WebAudio API sample example
	Room $mol_audio_room
		status? => room_status?
		input /
			<= Sample $mol_audio_sample
				active? => sample_active?
				start => start
				loop? => loop?
				loop_default true
				buffer <= sample_buffer null
	sub /
		<= List $mol_list rows /
			<= Sample_url_field $mol_form_field
				name \Sample url
				control <= Sample_url $mol_string
					value? <=> sample_url? \/mol/audio/demo/sample/drumloop.ogg
			<= Controls $mol_row sub /
				<= Active $mol_check_icon
					checked? <=> sample_active?
					title \Active
					Icon <= Active_icon $mol_icon_play
				<= Start $mol_button_minor
					click? <=> start_click? null
					title \Start
				<= Loop $mol_check_icon
					checked? <=> loop?
					title \Loop
					Icon <= Loop_icon $mol_icon_loop
			<= Room_status $mol_audio_status
				status? <=> room_status?
	tags /
		\sound
		\sample
	aspects /
		\Media/Audio
