$mol_check_group_demo $mol_example_small
	title \Group of checkboxes
	sub /
		<= Demo_items $mol_list
			rows /
				<= All $mol_check_group
					title \SPECIAL
					checks /
						<= Strength
						<= Perception
						<= Endurance
						<= Charisma
						<= Intelligence
						<= Agility
						<= Luck
				<= Partial $mol_list rows /
					<= Strength $mol_check_box
						title <= strength_title \Strength
						checked? <=> strength? false
					<= Perception $mol_check_box
						title <= perception_title \Perception
						checked? <=> perception? true
					<= Endurance $mol_check_box
						title <= endurance_title \Endurance
						checked? <=> endurance? false
					<= Charisma $mol_check_box
						title <= charisma_title \Charisma
						checked? <=> charisma? false
					<= Intelligence $mol_check_box
						title <= intelligence_title \Intelligence
						checked? <=> intelligence? true
					<= Agility $mol_check_box
						title <= agility_title \Agility
						checked? <=> agility? true
					<= Luck $mol_check_box
						title <= luck_title \Luck
						checked? <=> luck? true
	tags /
		\multi
		\group
	aspects /
		\Widget/Control/Button
