$hyoo_bench_app $mol_book2
	samples? /string
	pages /
		<= Addon_page $mol_page
			title <= addon_title @ \Samples
			Tools => Menu_tools
			head /
				<= Filter $mol_search
					query?val <=> filter?val \
				<= Menu_tools
			tools <= menu_tools /
				<= Source_link $mol_link_source
					uri <= source_link \https://github.com/hyoo-ru/bench.hyoo.ru
			body /
				<= Menu $mol_list
					rows <= menu_options /
		<= Main_page $mol_page
			title <= title
			body /
				<= Descr $mol_text
					text <= description \
				<= Param_fields $mol_view
					sub <= param_fields /
				<= Result $mol_bench
					result <= result null
					col_head_title!id
						<= result_col_title!id \
					col_sort?val <=> result_col_sort?val \
		<= Sandbox_page $mol_page
			title <= sandbox_title @ \Sandbox
			Body <= Sandbox $mol_frame
				uri <= bench?val \//bench.hyoo.ru/list/
	-
	Menu_option!id $mol_check_box
		minimal_height 36
		checked?val <=> menu_option_checked!id?val false
		title <= sample_title!id \
	-
	Param!id $mol_labeler
		title <= param_title!id \
		content /
			<= Param_value!id $mol_number
				value?val <=> param_value!id?val 0
				precision_change <= param_precision!id 0
	-
	result_col_title_sample @ \Sample
