$mol_calendar_demo_holiday $mol_example_small
	title \Days of month 2018-01 with custom holidays
	holidays /
		\2018-01-01
		\2018-01-02
		\2018-01-03
		\2018-01-04
		\2018-01-05
		\2018-01-06
		\2018-01-07
		\2018-01-08
		\2018-01-13
		\2018-01-14
		\2018-01-20
		\2018-01-21
		\2018-01-27
		\2018-01-28
	sub /
		<= Calendar $mol_calendar
			month_string <= month \2018-01
			day_holiday* <= holiday* false
	tags /
		\month
	aspects /
		\Widget/Grid
		\Type/Date
