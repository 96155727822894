$mol_search_jumper $mol_search
	Root $mol_view
	Index $mol_paginator
		value? <=> index? 0
		forward?event => forward?event
		backward?event => backward?event
	plugins /
		^
		<= Backward $mol_hotkey
			mod_shift true
			key * enter?event <=> backward?event
		<= Forward $mol_hotkey
			key *
				enter?event <=> forward?event
				escape? <=> escape? null
