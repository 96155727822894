$mol_form_demo $mol_example
	title \Sign Up form demo
	message *
		required \Required
		adult \18+ only
		no_spaces \No spaces!
		need_more_letters \{count} or more letters
		need_at \@ is required
		only_one_at \At most one @
		no_tld \At least 2 level domain
		dots_inside \Dots can't be at edge
		no_space_domain \No space in domain name
		need_username \Username required
	sub /
		<= Form $mol_form
			body /
				<= Avatars_field $mol_form_field
					name \Avatars
					bid <= avatars_bid \
					Content <= Avatars_control $mol_attach
						items? <=> avatars? /string
				<= Names $mol_form_group sub /
					<= Name_first_field $mol_form_field
						name \First Name
						bid <= name_first_bid \
						Content <= Name_first_control $mol_string
							hint \Jack
							value? <=> name_first? \
					<= Name_nick_field $mol_form_field
						name \Nick Name
						bid <= name_nick_bid \
						Content <= Name_nick_control $mol_string
							hint \Capitan
							value? <=> name_nick? \
					<= Name_second_field $mol_form_field
						name \Second Name
						bid <= name_second_bid \
						Content <= Name_second_control $mol_string
							hint \Sparrow
							value? <=> name_second? \
				<= Parameters $mol_form_group sub /
					<= Age_field $mol_form_field
						name \Age
						bid <= age_bid \
						Content <= Age_control $mol_number
							value? <=> age? 0
					<= Sex_field $mol_form_field
						name <= sex_label \Sex
						bid <= sex_bid \
						Content <= Sex_control $mol_switch
							value? <=> sex? \
							options <= sex_options *
								male \Male
								intersex \Intersex
								female \Female
					<= Color_field $mol_form_field
						name \Skin color
						bid <= color_bid \
						Content <= Color_control $mol_select
							value? <=> color? \
							dictionary *
								\
									\❔
								white \⬜ White
								yellow \🟨 Yellow
								brown \🟫 Brown
								red \🟥 Red
				<= Mail_field $mol_form_field
					name \E-mail
					bid <= mail_bid \
					Content <= Mail_control $mol_string
						hint \name@domain.com
						value? <=> mail? \
			submit? <=> signup? null
			submit_allowed => signup_allowed
			buttons /
				<= Signup $mol_button_major
					title \Sign Up
					click? <=> signup? null
					enabled <= signup_allowed
				<= Result $mol_status
					message <= result? \
	tags /
		\$mol_form_field
		\$mol_button
		\$mol_row
		\$mol_string
		\form
		\bids
		\validation
		\field
	aspects /
		\Widget/Form
