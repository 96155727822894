$mol_expander_demo $mol_example_small
	title \Simple spoiler
	sub /
		<= Expander $mol_expander
			title \Lorem Ipsum
			content /
				<= Content $mol_filler
	tags /
		\expander
		\accordion
		\expand
		\container
		\fold
	aspects /
		\Widget/Layout
