$mol_gallery_demo $mol_example
	title \Gallery of cards
	count 101
	sub /
		<= App $mol_gallery
			items <= items /
	Item* $mol_stack
		sub /
			<= Item_image* $mol_avatar
				id <= item_title* \
	tags /
		\gallery
		\image
		\adaptive
		\masonry
	aspects /
		\Widget/Layout
